import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static values = {
    publicClientKey: String,
    apiLoginId: String
  }
  static targets = ['nonce', 'number', 'month', 'year', 'cvv', 'submit']
  static acceptJSFields = ['number', 'month', 'year', 'cvv']

  connect() {
    this.restoreSensitiveData()
  }

  submit(e) {
    e.preventDefault()

    this.removeAcceptJSErrors()
    this.submitTarget.disabled = true
    // this.submitTarget.value = this.submitTarget.dataset.disableWith

    // Create nonce with Authorize.net Accept.js
    Accept.dispatchData(this.getSecureData(), (response) => {
      if (response.messages.resultCode === 'Error') {
        // console.log(response)
        response.messages.message.forEach((message) => {
          switch (message.code) {
            case 'E_WC_05':
              this.addError(this.numberTarget, message.text)
              break
            case 'E_WC_06':
              this.addError(this.monthTarget, message.text)
              break
            case 'E_WC_07':
              this.addError(this.yearTarget, message.text)
              break
            case 'E_WC_08':
              this.addError(this.monthTarget, message.text)
              break
            case 'E_WC_15':
              this.addError(this.cvvTarget, message.text)
              break
            default:
              this.addError(this.numberTarget, message.text)
          }
        })

        this.submitTarget.disabled = false
        return // halt execution on errors
      }

      this.nonceTarget.value = response.opaqueData.dataValue
      this.hideSensitiveData()
      this.element.requestSubmit()
    })
  }

  getSecureData() {
    let authData = {
      clientKey: this.publicClientKeyValue,
      apiLoginID: this.apiLoginIdValue
    }

    let cardData = {
      cardNumber: this.numberTarget.value,
      month: this.monthTarget.value,
      year: this.yearTarget.value,
      cardCode: this.cvvTarget.value,
    }

    let secureData = {
      authData: authData,
      cardData: cardData
    }

    return secureData
  }

  hideSensitiveData() {
    let number = this.numberTarget.value
    this.numberStorageField().value = number
    this.numberTarget.value = "X".repeat(number.length - 4) + number.slice(-4)

    let cvv = this.cvvTarget.value
    this.cvvStorageField().value = cvv
    this.cvvTarget.value = "XXX"
  }

  restoreSensitiveData() {
    let number = this.numberStorageField().value
    if (number !== "") {
      this.numberTarget.value = number
      this.numberStorageField().value = ""
    }

    let cvv = this.cvvStorageField().value
    if (cvv !== "") {
      // console.log("restoring cvv")
      this.cvvTarget.value = cvv
      this.cvvStorageField().value = ""
    }
  }

  numberStorageField() {
    let numberStorageId = 'credit-card-number-storage'
    let numberStorageField = document.getElementById(numberStorageId)

    if (numberStorageField === null) {
      numberStorageField = document.createElement('input')
      numberStorageField.setAttribute('type', 'hidden')
      numberStorageField.setAttribute('id', numberStorageId)
      document.body.appendChild(numberStorageField)
    }

    return numberStorageField
  }

  cvvStorageField() {
    let cvvStorageId = 'credit-card-cvv-storage'
    let cvvStorageField = document.getElementById(cvvStorageId)

    if (cvvStorageField === null) {
      cvvStorageField = document.createElement('input')
      cvvStorageField.setAttribute('type', 'hidden')
      cvvStorageField.setAttribute('id', cvvStorageId)
      document.body.appendChild(cvvStorageField)
    }

    return cvvStorageField
  }

  addError(formField, message) {
    let errorMessageElement = formField.parentElement.getElementsByClassName('invalid-feedback')[0]

    if (errorMessageElement === undefined) {
      errorMessageElement = document.createElement('div')
      errorMessageElement.classList.add('invalid-feedback')
      formField.parentElement.appendChild(errorMessageElement)
    }

    formField.classList.add('is-invalid')
    errorMessageElement.innerHTML = message
  }

  removeError(formField) {
    formField.classList.remove('is-invalid')
    Array.from(formField.parentElement.getElementsByClassName('invalid-feedback')).forEach(el => el.remove())
  }

  removeAcceptJSErrors() {
    this.constructor.acceptJSFields.forEach(fieldName => this.removeError(this[fieldName + 'Target']))
  }

}