import { Controller } from '@hotwired/stimulus'

export default class extends Controller {

  connect() {
    this.navbar = document.querySelector('.navbar-sticky');
    this.navbarHeight = this.navbar.offsetHeight;
    this.scrollOffset = 200;

    this.toggleStickyNav();
    this.setupListeners();
  }

  toggleStickyNav() {
    if (window.scrollY > this.scrollOffset) {
      document.body.style.paddingTop = this.navbarHeight + 'px';
      this.navbar.classList.add('navbar-stuck');
    } else {
      document.body.style.paddingTop = '';
      this.navbar.classList.remove('navbar-stuck');
    }
  }

  setupListeners() {
    window.addEventListener('scroll', (e) => {
      this.toggleStickyNav()
    });

    this.navbar.addEventListener('turbo:before-morph-attribute', (e) => {
      // The incoming element does not have navbar-stuck class on it and creates a flicker
      // effect when it's morphed and navbar-stuck is removed and the added back.
      if (e.target.classList.contains('navbar-stuck')) {
        document.body.style.paddingTop = this.navbarHeight + 'px';
        e.preventDefault();
      }
    });

    let toggler = document.querySelector('.navbar-stuck-toggler'),
      stuckMenu = document.querySelector('.navbar-stuck-menu');

    toggler.addEventListener('click', function (e) {
      stuckMenu.classList.toggle('show');
      e.preventDefault();
    });
  }
}