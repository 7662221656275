import { Controller } from '@hotwired/stimulus'
import { register, render } from 'timeago.js';

export default class extends Controller {
  static values = {
    locale: { type: String, default: 'en_US' }
  }

  connect() {
    switch (this.localeValue) {
      case 'en_DeliveryEstimates':
        this.registerDeliveryEstimatesLocale()
        break;
      case 'en_CheckoutCutoff':
        this.registerCheckoutCutoffLocale()
      default:
        break;
    }

    render(this.element, this.localeValue)
  }

  registerDeliveryEstimatesLocale() {
    const timePeriods = ['second', 'minute', 'hour', 'day', 'week', 'month', 'year'];

    const localeFunc = function (diff, idx) {
      if (idx === 0) return ['just now', 'right now'];
      let unit = timePeriods[Math.floor(idx / 2)];
      if (diff > 1) unit += 's';
      return [`${diff} ${unit} ago`, `within ${diff} ${unit}`];
    }

    register('en_DeliveryEstimates', localeFunc);
  }

  registerCheckoutCutoffLocale() {
    const localeFunc = function (diff, idx, totalSec) {
      if (idx === 0) return ['just now', 'right now'];

      var days = Math.floor(totalSec / (60 * 60 * 24));
      var hours = Math.floor((totalSec % (60 * 60 * 24)) / (60 * 60));
      var minutes = Math.floor((totalSec % (60 * 60)) / (60));
      var seconds = Math.floor(totalSec);

      if (days > 0) {
        return [`${days}d ${hours}h ${minutes}m ago`, `${days}d ${hours}h ${minutes}m`];
      } else if (hours > 0) {
        return [`${hours}h ${minutes}m ago`, `${hours}h ${minutes}m`];
      } else if (minutes > 0) {
        return [`${minutes}m ago`, `in ${minutes}m`];
      } else {
        return [`${seconds}s ago`, `in ${seconds}s`];
      }
    }

    register('en_CheckoutCutoff', localeFunc);
  }
}