import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static values = { expiresIn: Number }
  static targets = ['submissionBlocker', 'submitButton', 'submitButtonContent', 'summaryTotals']

  connect() {
    this.toggleSubmitButton();

    if (this.expiresInValue) {
      // this.timeout = setTimeout(() => {
      // }, this.expiresInValue * 1000)
    }
  }

  toggleSubmitButton() {
    let disabled = this.hasSubmissionBlockerTarget

    this.submitButtonTargets.forEach(target => {
      if (disabled) {
        target.setAttribute('disabled', 'disabled');
        target.innerHTML = '<span class="show-when-disabled">Place Your Order</span>'
      } else {
        target.removeAttribute('disabled');
        target.innerHTML = this.submitButtonContentTarget.innerHTML
      }
    })
  }

  submissionBlockerTargetConnected() {
    this.toggleSubmitButton();
  }

  submissionBlockerTargetDisconnected() {
    this.toggleSubmitButton();
  }

  deliveryMethodSelected() {
    this.summaryTotalsTarget.classList.add('placeholder-glow');
    this.summaryTotalsTarget.dataset.checkoutTarget = 'submissionBlocker';
  }
}