import { Controller } from '@hotwired/stimulus'
import Modal from 'bootstrap/js/dist/modal'

export default class extends Controller {
  static values = {
    showOnConnect: { type: Boolean, default: false }
  }

  connect() {
    document.querySelectorAll('.modal-backdrop').forEach(el => el.remove());

    this.modalElement = new Modal(this.element, {})
    this.setEventListeners()

    if (this.showOnConnectValue) { this.show() }
  }

  disconnect() {
    let that = this
    this.element.removeEventListener('hidden.bs.modal', function (event) {
      that.dismissModal()
    })
  }

  setEventListeners() {
    let that = this
    this.element.addEventListener('hidden.bs.modal', function (event) {
      that.dismissModal()
    })
  }

  show() {
    this.modalElement.show()
  }

  hide() {
    this.modalElement.hide()
  }

  dismissModal() {
    this.modalElement.dispose()

    let body = document.querySelector('body')
    body.style.overflow = null
    body.style.paddingRight = null

    let turboFrame = this.element.closest('turbo-frame')
    if (turboFrame) { turboFrame.src = undefined }

    this.element.remove();
  }
}