import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = [ 'checkbox', 'toggle', 'button', 'values' ]

  check(e) {
    this.toggleButtons()
    this.setValues()
  }

  toggle(e) {
    const scope = e.currentTarget.dataset.clickAllScope;
    const checked = e.currentTarget.checked;

    this.toggleTargets.forEach(toggle => {
      if (toggle == e.currentTarget) { return }
      toggle.checked = false 
    })

    this.checkboxTargets.forEach(target => {
      if (scope == undefined || target.dataset[scope]) {
        target.checked = checked 
      } else {
        target.checked = false
      }
    })

    this.toggleButtons()
    this.setValues()
  }

  checkedValues() {
    let checkedValues = []

    this.checkboxTargets.forEach((element, id) => {
      if (element.checked) { checkedValues.push(element.value) }
    })

    return checkedValues
  }

  toggleButtons() {
    if ( !this.hasButtonTarget ) { return }

    this.buttonTargets.forEach(target => {
      target.disabled = (this.checkedValues().length === 0)
    })
  }

  setValues() {
    if ( !this.hasValuesTarget ) { return }

    this.valuesTarget.value = this.checkedValues().toString()
  }
}
