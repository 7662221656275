import { Controller } from '@hotwired/stimulus'
import Offcanvas from 'bootstrap/js/dist/offcanvas'

export default class extends Controller {
  connect() {
    this.offcanvasElement = new Offcanvas(this.element, {})
    this.offcanvasElement.toggle()
    this.setEventListeners()
  }

  disconnect() {
    let that = this

    this.element.removeEventListener('hidden.bs.offcanvas', function (event) {
      that.dismissCartDrawer()
    })

    this.dismissCartDrawer()
  }

  setEventListeners() {
    let that = this
    this.element.addEventListener('hidden.bs.offcanvas', function (event) {
      that.dismissCartDrawer()
    })
  }

  hide() {
    this.offcanvasElement.hide()
  }

  dismissCartDrawer() {
    this.offcanvasElement.dispose()
    this.element.closest('turbo-frame').src = undefined
    this.element.remove();
  }
}