export function getCookie(cName) {
  const name = cName + "=";
  const decoded = decodeURIComponent(document.cookie); //to be careful
  const cookieArray = decoded.split('; ');
  let res;
  cookieArray.forEach(val => {
    if (val.indexOf(name) === 0) res = val.substring(name.length);
  })
  return res;
}

export function setCookie(cName, cValue, expDays) {
  let date = new Date();
  date.setTime(date.getTime() + (expDays * 24 * 60 * 60 * 1000));
  const expires = "expires=" + date.toUTCString();
  document.cookie = cName + "=" + cValue + "; " + expires + "; path=/";
}