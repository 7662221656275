import { Controller } from '@hotwired/stimulus'
import Tooltip from 'bootstrap/js/dist/tooltip'

export default class extends Controller {
  connect() {
    this.tooltipElement = new Tooltip(this.element, {})
  }

  disconnect() {
    this.tooltipElement.dispose()
  }
}