import { Controller } from '@hotwired/stimulus'
import Splide from '@splidejs/splide'

export default class extends Controller {
  static values = { options: Object }

  connect() {
    let defaults = {
      type: 'slide',
      perPage: 5,
      perMove: 5,
      gap: '0.5rem',
      pagination: false,
      breakpoints: {
        1200: {
          perPage: 4,
          perMove: 4
        },
        992: {
          perPage: 3,
          perMove: 3
        },
        768: {
          perPage: 3,
          perMove: 3
        },
        576: {
          perPage: 2,
          perMove: 2
        }
      }
    }

    let options = Object.assign({}, defaults, this.optionsValue);
    this.carousel = new Splide(this.element, options).mount();
  }
}