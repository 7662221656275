import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = [
    'radioButton', 'streetOneInput', 'streetTwoInput', 'cityInput', 'stateInput',
    'postalCodeInput', 'countryInput', 'addressTypeInput'
  ]

  connect() {
    this.radioButtonTargets.forEach(radioButton => {
      if (radioButton.checked) {
        this.applyValues(radioButton)
      }
    })
  }

  select(event) {
    this.applyValues(event.target)
  }

  applyValues(element) {
    this.streetOneInputTarget.value = element.dataset.streetOne

    if (element.dataset.streetTwo) {
      this.streetTwoInputTarget.value = element.dataset.streetTwo
    }

    this.cityInputTarget.value = element.dataset.city
    this.stateInputTarget.value = element.dataset.state
    this.postalCodeInputTarget.value = element.dataset.postalCode
    this.countryInputTarget.value = element.dataset.country
    this.addressTypeInputTarget.value = element.dataset.addressType
  }
}