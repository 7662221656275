import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = [ 'input' ]

  increment() {
    let value = parseInt(this.inputTarget.value) + 1

    if (this.inputTarget.max) {
      value = Math.min(value, this.inputTarget.max)
    }

    this.updateInput(value)
  }

  decrement() {
    let value = parseInt(this.inputTarget.value) - 1

    if (this.inputTarget.min) {
      value = Math.max(value, this.inputTarget.min)
    }

    this.updateInput(value)
  }

  updateInput(value) {
    this.inputTarget.value = value
    this.inputTarget.dispatchEvent(new Event('change'))
  }
}