import { Controller } from '@hotwired/stimulus'
import { debounce } from "../helpers/timing_helpers"
import Alert from 'bootstrap/js/dist/alert'

export default class extends Controller {
  static values = {
    autoClose: Number
  }

  connect() {
    this.alertElement = new Alert(this.element, {})

    if (this.autoCloseValue) {
      this.#debouncedClose()
    }
  }

  close() {
    this.alertElement.close()
  }

  #debouncedClose = debounce(this.close.bind(this), this.autoCloseValue)
}