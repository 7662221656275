import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static values = {
    after: { type: Number, default: 0 },
    url: { type: String, default: '' }
  }

  connect() {
    this.startCountdown()
  }

  disconnect() {
    if (this.timeout) { clearTimeout(this.timeout) }
  }

  startCountdown() {
    if (this.afterValue <= 0 || this.urlValue == '') { return }

    this.timeout = setTimeout(() => {
      Turbo.session.refresh(this.urlValue)
    }, this.afterValue * 1000)
  }
}