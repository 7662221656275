import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = [ 'button', 'submitOnConnect' ]
  static values = {
    debounce: { type: Number, default: 400 },
    reloadUrl: { type: String, default: '' }
  }

  submit(e) {
    clearTimeout(this.timeout)
    this.timeout = setTimeout(() => {
      if (e != undefined && e.target.dataset.url) { this.element.action = e.target.dataset.url }
      this.element.requestSubmit()
    }, this.debounceValue)
  }
  
  reload(e) {
    e.preventDefault()

    this.element.action = this.reloadUrlValue
    this.element.method = 'GET'
    this.element.requestSubmit()
  }

  disableOtherButtons(e) {
    this.buttonTargets.forEach(button => {
      if (e.target.parentElement == button) { return }

      button.classList.add('disabled')
    })
  }

  submitOnConnectTargetConnected() {
    this.submit()
  }
}