export default {
  set: function (name, value, ttl, domain) {
    let expires = "";
    let cookieDomain = "";
    if (ttl) {
      const date = new Date();
      date.setTime(date.getTime() + (ttl * 60 * 1000));
      expires = "; expires=" + date.toUTCString();
    }
    if (domain) {
      cookieDomain = "; domain=" + domain;
    }
    document.cookie = name + "=" + encodeURIComponent(value) + expires + cookieDomain + "; path=/";
  },
  get: function (cName) {
    const name = cName + "=";
    const decoded = decodeURIComponent(document.cookie); //to be careful
    const cookieArray = decoded.split('; ');
    let res;
    cookieArray.forEach(val => {
      if (val.indexOf(name) === 0) res = val.substring(name.length);
    })
    return res;
  },
  destroy: function (name) {
    this.set(name, "", -1);
  }
};