import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['container']

  process(e) {
    e.preventDefault();

    e.target.classList.toggle('active');

    if (e.target.dataset.hideOnProcess) {
      e.target.classList.toggle('d-none');
    }

    let value = e.currentTarget.value;

    this.containerTargets.forEach((target) => {
      let uncoverableValue = target.dataset.uncoverableValue;

      if (uncoverableValue == [] || uncoverableValue == undefined) {
        target.classList.toggle('d-none');
      } else if (uncoverableValue.includes(value)) {
        target.classList.remove('d-none');
      } else {
        target.classList.add('d-none');
      }
    });
  }
}
