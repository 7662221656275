import '@hotwired/turbo-rails'
import './controllers/website'

// START Boostrap
import 'bootstrap/js/dist/alert';
import 'bootstrap/js/dist/button';
// import 'bootstrap/js/dist/carousel';
import 'bootstrap/js/dist/collapse';
import 'bootstrap/js/dist/dropdown';
// import 'bootstrap/js/dist/modal';
import 'bootstrap/js/dist/offcanvas';
// import 'bootstrap/js/dist/popover';
// import 'bootstrap/js/dist/scrollspy';
import 'bootstrap/js/dist/tab';
// import 'bootstrap/js/dist/toast';
// import 'bootstrap/js/dist/tooltip';
// END Bootstrap

import './heya';

// Start other libraries
import Drift from 'drift-zoom';

document.addEventListener('turbo:before-fetch-response', function (event) {
  if (typeof (event.detail.fetchResponse) !== 'undefined') {
    let redirectLocation = event.detail.fetchResponse.response.headers.get('turbo_location')
    if (redirectLocation) {
      event.preventDefault()
      Turbo.cache.clear()
      Turbo.visit(redirectLocation)
    }
  }
})

document.addEventListener('turbo:load', function (event) {
  let images = document.querySelectorAll('.image-zoom');

  for (let i = 0; i < images.length; i++) {
    new Drift(images[i], {
      paneContainer: images[i].parentElement.querySelector('.image-zoom-pane')
    });
  }
})