import { Controller } from '@hotwired/stimulus'
import { get } from '@rails/request.js'
import { isbot } from 'isbot'

export default class extends Controller {

  connect() {
    if (isbot(navigator.userAgent)) { return }

    get('/delivery-location', {
      responseKind: 'turbo-stream',
    })
  }
}